import "./UnhappyGuest.scss";

import React, { useState } from "react";

import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import UnhappyGuestForm from "./UnhappyGuestForm";
import UnhappyGuestRemove from "./UnhappyGuestRemove";

const UnhappyGuest = ({ booking, refreshThread }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const renderUnhappyGuestModal = title => {
    return (
      <Modal title={title} close={toggleModal}>
        {booking.unhappy_guest ? (
          <UnhappyGuestRemove
            closeModal={toggleModal}
            booking={booking}
            refreshThread={refreshThread}
          />
        ) : (
          <UnhappyGuestForm
            closeModal={toggleModal}
            booking={booking}
            refreshThread={refreshThread}
          />
        )}
      </Modal>
    );
  };

  if (!booking) {
    return null;
  }

  const title = booking.unhappy_guest
    ? "😟Unhappy - mark happy"
    : "Mark unhappy";

  const notes = booking.unhappy_guest ? booking.unhappy_guest_notes : null;

  return (
    <>
      {showModal && renderUnhappyGuestModal(title)}

      <Button
        onClick={toggleModal}
        className="UnhappyGuest__button"
        buttonType="link"
      >
        {title}
      </Button>
      {notes && <p>Unhappy guest notes: {notes}</p>}
    </>
  );
};

export default UnhappyGuest;
